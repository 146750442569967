import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/assessaai_logo2.png'; // Correct logo import
import './Login.css'; // CSS for styling

const Login = () => {
  return (
    <div className="auth-container">
      {/* Logo Section */}
      <div className="logo-container mb-4 flex justify-center">
        <img src={logo} alt="ASSESSA AI Logo" className="h-12" />
      </div>

      <h2 className="text-2xl font-bold text-center mb-6">Login</h2>

      <form>
        <div className="mb-4">
          <input
            type="email"
            placeholder="Email"
            className="input-field"
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            placeholder="Password"
            className="input-field"
          />
        </div>
        <button className="btn-primary w-full">Login</button>
      </form>

      <p className="text-center mt-4">
        Don't have an account? <Link to="/signup" className="text-blue-600 hover:underline">Sign Up</Link>
      </p>
    </div>
  );
};

export default Login;
